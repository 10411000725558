<template>
  <div class="flex items-center flex-wrap space-x-0.5">
    <div v-for="n in 26" :key="n">
      <a
        href=""
        @click.prevent="
          documentationStore.viewArticle(
            placeholderArticle,
            String.fromCharCode(n + 64),
          )
        "
        >{{ String.fromCharCode(n + 64) }}</a
      >
      <template v-if="n !== 26">|</template>
    </div>
  </div>
</template>

<script setup>
import { ref } from "vue";
import { useDocumentationStore } from "@/stores/documentation";

const documentationStore = useDocumentationStore();

const placeholderArticle = ref({ name: "Tower Hunt glossary" });
</script>
